import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ReduxToastr from "react-redux-toastr";
import axios from "axios";
import "./index.scss";
import Spinner from "./components/spinner/spinner";

window.history.scrollRestoration = "auto";

const LazyApp = lazy(() => import("./app/app"));
// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error);
    if (error.response.status === 401) {
      localStorage.clear();
      store.dispatch({ type: "user_clear" });
    }
    // eslint-disable-next-line
    return Promise.reject(error);
  }
);
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <LazyApp store={store} />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="bounceInDown"
        transitionOut="bounceOutUp"
        progressBar
        closeOnToastrClick
      />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
